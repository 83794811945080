import * as React from 'react';
import './KnowMoreAbout.scss';
import Aquarium from '../Aquarium/Aquarium';

const KnowMoreAbout = (props) => {
  const { displayed } = props;
  return (
    <div className={`know-more-about ${displayed ? "displayed" : "not-displayed"}`}>
      <div className="know-more-about-content">
        <p className="title">
          Opening <span style={{ color: "#fab81b", fontWeight: "bold" }}>Machine Learning</span> innovation
        </p>
        <p  className="title">
          to your entire organization
        </p>
        <div className="know-more-about-content-bottom-div">
          <div className="know-more-about-content-bottom-div-content">
            <button
              style={{ 
                position: "absolute",
                zIndex: 3,
                left: "45%"
              }}
              onClick={() => window.open("https://www.mlreef.com/book-a-demo")} className="book-a-demo"
            >
              Book a demo
            </button>
            <a
              href='https://www.mlreef.com'
              target="_blank"
              style={{ 
                color: 'white', textAlign: "center", fontSize: "1.3rem",
                position: "absolute",
                left: "46.5%",
                marginTop: "9rem",
                textDecoration: "none"
              }}
            >
              or
              {' '}
              <span style={{ color: "var(--primary)" }}>
                dive deeper
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default (props) => {
  const { displayed } = props;
  return (
  <Aquarium className={`home-page-top-banner bg-dark displayed`}>
    <KnowMoreAbout displayed={displayed} />
  </Aquarium>
)}
