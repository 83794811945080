import * as React from 'react';
import './CirclesSection.scss';
import { buildCircles } from './helpers';

const CirclesSection = (props) => {
  const { displayed } = props;
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  React.useLayoutEffect(() => {
    const ctx = canvasRef.current?.getContext("2d");
    if (ctx) {
      buildCircles(ctx);
    }
  }, []);
  return (
    <div className={`circles-section displayed`}>
      <p className="text-1">
        <span style={{ color: "var(--operationColor)", fontWeight: "bold" }}>
          Multipersona 
        </span>
        {' '}
          Machine Learning use cases work best if
        {' '}
        <span style={{ color: "#32afc3", fontWeight: "bold" }}>
          Data Science 
        </span>
        {' '}
          is combined with 
        {' '}
        <span style={{ color: "#fab81b", fontWeight: "bold" }}>
          Domain Knowledge
        </span> 
      </p>      
      <canvas className="drawing-section" width={700} height={350} ref={canvasRef}></canvas>
    </div>
  )
}

export default CirclesSection;
