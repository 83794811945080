import {TweenMax, Power1, TweenLite} from "gsap";


class Circle {
  private pos: Point;
  private radius: any;
  private color: any;
  private active: any;

  // constructor
  constructor(pos: Point, rad, color) {
    this.pos = pos;
    this.radius = rad;
    this.color = color;
  }

  setActive(act) {
    this.active = act;
  }

  getActive() {
    return this.active;
  }

  draw(ctx) {
    if (!this.active) return;
    ctx.beginPath();
    ctx.arc(this.pos.getX(), this.pos.getOriginY(), this.radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = 'rgba(156,217,249,' + this.active + ')';
    ctx.fill();
  };

  
}

class Point {
  private x: any;
  private originX: any;
  private y: any;
  private originY: any;
  private closest: Array<Point>;
  private circle: Circle;
  private active: any;
  
  public getActive(): any {
    return this.active;
  }
  public setActive(value: any) {
    this.active = value;
  }

  constructor (px, py) {
    this.x = px;
    this.originX = px;
    this.y = py;
    this.originY = py;
  }
  
  setClosest(closest: Array<Point>) {
    this.closest = closest;
  }

  getClosest() {
    return this.closest;
  }

  setCircle(circ: Circle){
    this.circle = circ;
  };

  getCircle(){
    return this.circle;
  };


  getX() {
    return this.x;
  }

  getY() {
    return this.y;
  }

  setX(x) {
    this.x = x;
  }

  setY(y) {
    this.y = y;
  }


  
  getOriginX() {
    return this.originX;
  }
  
  getOriginY() {
    return this.originY;
  }
}

export const generateEffect = (largeHeader: HTMLElement, canvas) => {
  let width, height, ctx, points: Array<Point>, target: Point, animateHeader = true;

  // Main
  initHeader();
  initAnimation();
  addListeners();

  function initHeader() {
    width = document.body.scrollWidth;
    height = window.innerHeight;
    target = new Point(width / 2, height / 2);

    largeHeader.style.height = height + 'px';

    canvas.width = width;
    canvas.height = height;
    ctx = canvas.getContext('2d');

    // create points
    points = Array<Point>();
    const widthRate = width / 40;
    const heightRate = height / 40;
    for (var x = 0; x < width; x = x + widthRate) {
      for (var y = 0; y < height; y = y + heightRate) {
        const randomNum = Math.random();
        var px = x + randomNum * widthRate;
        var py = y + randomNum * heightRate;
        let p = new Point(px, py);

        points.push(p);
      }
    }

    // for each point find the 5 closest points
    for (var i = 0; i < points.length; i++) {
      var closest = Array<Point>();
      var p1 = points[i];
      for (var j = i + 1; j < points.length; j++) {
        var p2 = points[j]
        if (p1 !== p2) {
          var placed = false;
          for (var k = 0; k < 5; k++) {
            if (!placed) {
              if (closest[k] == undefined) {
                closest[k] = p2;
                placed = true;
              }
            }
          }

          for (var k = 0; k < 5; k++) {
            if (!placed) {
              if (getDistance(p1, p2) < getDistance(p1, closest[k])) {
                closest[k] = p2;
                placed = true;
              }
            }
          }
        }
      }
      p1.setClosest(closest);
      points[i] = p1;
    }

    // assign a circle to each point
    for (const i in points) {
      var c = new Circle(points[i], 2 + Math.random() * 2, 'rgba(255,255,255,0.3)');
      points[i].setCircle(c);
    }
  }

  // Event handling
  function addListeners() {
    if (!('ontouchstart' in window)) {
      window.addEventListener('mousemove', mouseMove);
    }
/*     window.addEventListener('scroll', scrollCheck);
*/
   window.addEventListener('resize', resize); 
  }

  function mouseMove(e) {
    let posx, posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    }
    else if (e.clientX || e.clientY) {
      posx = e.clientX/*  + document.body.scrollLeft + document.documentElement.scrollLeft; */
      posy = e.clientY /* + document.body.scrollTop + document.documentElement.scrollTop; */
    }
    target.setX(posx);
    target.setY(posy);
  }

  function scrollCheck() {
    if (document.body.scrollTop > height) animateHeader = false;
    else animateHeader = true;
  }

  function resize() {
    width = window.innerWidth;
    height = window.innerHeight;
    largeHeader.style.height = height + 'px';
    canvas.width = width;
    canvas.height = height;
  }

  // animation
  function initAnimation() {
    animate();
    for (var i in points) {
      shiftPoint(points[i]);
    }
  }

  function animate() {
    if (animateHeader) {
      ctx.clearRect(0, 0, width, height);
      for (var i in points) {
        // detect points in range
        const currentPoint = points[i];
        const currentCircle = currentPoint.getCircle();
        if (Math.abs(getDistance(target, points[i])) < 4000) {
          currentPoint.setActive(0.3);
          currentCircle.setActive(0.6);
        } else if (Math.abs(getDistance(target, currentPoint)) < 20000) {
          currentPoint.setActive(0.1);
          currentCircle.setActive(0.3);
        } else if (Math.abs(getDistance(target, currentPoint)) < 40000) {
          currentPoint.setActive(0.02);
          currentCircle.setActive(0.1);
        } else {
          currentPoint.setActive(0);
          currentCircle.setActive(0);
        }
        currentPoint.setCircle(currentCircle);
        points[i] = currentPoint;

        drawLines(currentPoint);
        currentCircle.draw(ctx);
      }
    }
    requestAnimationFrame(animate);
  }

  function shiftPoint(p: Point) {
    TweenLite.to(p, 1 + 1 * Math.random(), {
      x: p.getOriginX() - 50 + Math.random() * 100,
      y: p.getOriginY() - 50 + Math.random() * 100, ease: Power1.easeInOut,
      onComplete: function () {
        shiftPoint(p);
      }
    });
  }

  // Canvas manipulation
  function drawLines(p: Point) {
    if (!p.getActive()) return;
    const closestPoints = p.getClosest();
    for (var i in closestPoints) {
      ctx.beginPath();
      ctx.moveTo(p.getX(), p.getY());
      ctx.lineTo(closestPoints[i].getX(), closestPoints[i].getY());
      ctx.strokeStyle = 'rgba(156,217,249,' + p.getActive() + ')';
      ctx.stroke();
    }
  }

  // Util
  function getDistance(p1: Point, p2: Point) {   
    return Math.pow(p1.getX() - p2.getX(), 2) + Math.pow(p1.getY() - p2.getY(), 2);
  }
};
