import React from 'react';
import './Aquarium.scss';

const Aquarium = (props) => {
  const { children, className } = props;

  return (
    <div className={`aquarium ${className}`}>
      <div className="aquarium-fishes">
        <div className="fish-container first">
          <div className="fish swimming" />
        </div>
        <div className="fish-container second">
          <div className="fish swimming" />
        </div>
        <div className="fish-container third">
          <div className="fish swimming" />
        </div>
        <div className="fish-container four">
          <div className="fish swimming" />
        </div>
      </div>
      <div className="aquarium-content">
        {children}
      </div>
    </div>
  );
};

export default Aquarium;
