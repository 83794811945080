import * as React from 'react';
import './Card.scss';

interface CardProps {
  title: string;
  description: React.ReactElement;
  imgUrl: string;
  icon: string;
  className:  string;
}

const Card: React.FC<CardProps> = ({ title, description, imgUrl, className, icon }: CardProps) => {
  const [readMore, setReadMore] = React.useState(false);
  return (
    <div className={`${className} card`}>
      <div className="card-content">
        <div className="card-content-head">
          <img src={imgUrl} alt="" width="100%"/>
        </div>
        <div className="card-content-title">
          <img src={icon} alt="" />
          <p>
            {title}
          </p>
        </div>
        {readMore && <div className="card-content-desc-container">
          {description}
        </div>}
        <div className="card-content-button-div" >
          <button
            onClick={() => setReadMore((oldReadMore) => !oldReadMore)}
          >
            Read {readMore ? "less" : "about it"}
          </button>
        </div>
      </div>
    </div>
  )
};

export default Card;