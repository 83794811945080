import * as React from 'react';
import { buildGraph } from './GraphFunctions';
import './GraphSection.scss';


const GraphSection = (props) => {
  const { displayed } = props;
  const graphSectionRef = React.useRef<HTMLDivElement>(null);
  const [phase, setPhase] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {      
      setPhase(phase === 0 ? 1 : 0);
    }, 12555);
    return () => {
      clearInterval(interval);
    };
  }, [phase]);
  
  React.useEffect(() => {
    buildGraph();
    const interval = setInterval(() => buildGraph(), 25100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div ref={graphSectionRef} className={`graph-section displayed`}>
      <div className="graph-section-content">
        <div className="graph-section-content-headers">
          <p>
            The Data Science process
            <span style={{ color: phase === 0 ? "var(--fuchsia)" : "#91BD44", fontWeight: "bold" }}>
              {phase === 0 ? " without " : " with "} domain knowledge
            </span>
          </p>
        </div>
        <div className="graph-section-content-zone">
          <svg width="1000" height="600"></svg>
        </div>
      </div>
    </div>
  )
}

export default GraphSection;
