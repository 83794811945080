import * as React from 'react';
import './DSBottleneckProblem.scss';
import bottleneckProblem from '../../images/bottleneckProblem.png'

const DSBottleneckProblem = (props) => {
  const { displayed } = props;
  return (
    <div className={`ds-bottleneck-problem displayed`}>
      <p className='title'>
        What is the <span style={{ color: "var(--modelColor)" }}>Data Science Bottleneck</span>?
      </p>
      <div className="ds-bottleneck-problem-content">
        <img src={bottleneckProblem} alt="" width={700} />
      </div>
    </div>
  )
}

export default DSBottleneckProblem;