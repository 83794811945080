import * as React from 'react';
import { generateEffect } from './helpers';
import './PointsEffect.scss';

interface PointsEffectProps {
  children: React.ReactNode[] | React.ReactNode
}

const PointsEffect = (props: PointsEffectProps) => {
  const rootElementRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  React.useEffect(() => {
    if (rootElementRef.current && canvasRef.current){
      generateEffect(rootElementRef.current, canvasRef.current);
    }
  }, []);
  
  return (
    <div ref={rootElementRef} className="points-effect">
      <canvas ref={canvasRef}></canvas>
      <div className="points-effect-content">
        {props.children}
      </div>
    </div>
  )
}

export default PointsEffect;
