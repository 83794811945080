import * as React from 'react';
import './DomainKnowledgeGap.scss';
import mlreefIc from '../../images/MLReef_Logo.png';

const DomainKnowledgeGap = (props) => {
  const { displayed } = props;
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  React.useLayoutEffect(() => {
    let ctx = canvasRef.current?.getContext("2d");
    const xCoordinateCircle = 380;
    const yCoordinateCircle = 160;
    const radiousCircle1 = 130;
    if (ctx) {
      ctx.beginPath();
      ctx.arc(xCoordinateCircle, yCoordinateCircle, radiousCircle1, 0, 2 * Math.PI);
      ctx.strokeStyle = "#32afc3";
      ctx.lineWidth = 7.5;
      ctx?.stroke();
      ctx.font = "1.2rem Comic Sans MS";
      ctx.fillStyle = "#32afc3";
      ctx.textAlign = "center";
      ctx.fillText("Domain Expert", 375, 180); 

      ctx.beginPath();
      ctx.arc(xCoordinateCircle, yCoordinateCircle, radiousCircle1 + 15, 0, 2 * Math.PI);
      ctx.strokeStyle = "#fab81b";
      ctx.lineWidth = 7.5;
      ctx?.stroke();
      ctx.font = "1.2rem Comic Sans MS";
      ctx.fillStyle = "#fab81b";
      ctx.textAlign = "center";
      ctx.fillText("Data Science", 375, 130);
      
      // plot the dotted line that enmarks the circles

      ctx.strokeStyle = "white";

      ctx.beginPath();
      ctx.setLineDash([5, 10]);
      ctx.moveTo(60, 0);
      ctx.lineTo(60, 320);
      ctx.stroke();

      ctx.beginPath();
      ctx.setLineDash([5, 10]);
      ctx.moveTo(60, 0);
      ctx.lineTo(600, 0);
      ctx.stroke();

      ctx.beginPath();
      ctx.setLineDash([5, 10]);
      ctx.moveTo(600, 0);
      ctx.lineTo(600, 320);
      ctx.stroke();

      ctx.beginPath();
      ctx.setLineDash([5, 10]);
      ctx.moveTo(600, 320);
      ctx.lineTo(60, 320);
      ctx.stroke();

      const imageInstance = new Image();
      imageInstance.src = mlreefIc;
      imageInstance.onload = () => {
        ctx?.drawImage(imageInstance, 80, 10, 180, 50);
      }
      
    }
  }, []);
  return (
    <div className={`domain-knowledge-gap displayed`}>
      <p className="title">
        MLReef solves the 
        {' '}
        <span style={{ color: "#fab81b", fontWeight: "bold" }}>
          Data Science Bottleneck 
        </span>  & <span style={{ color: "#32afc3", fontWeight: "bold" }}> Domain Knowledge Gap
        </span>
      </p>
      <div className="domain-knowledge-gap-content">
        <div className="domain-knowledge-gap-content-left">
          <canvas className="drawing-section" width={600} height={350} ref={canvasRef}></canvas>
        </div>
        <div className="domain-knowledge-gap-content-right">
          <p className="first-heading">
            MLReef is the first true
            {' '}
            <span style={{ color: "var(--operationColor)", fontWeight: "bold" }}>
            multipersona 
            </span>
            {' '}
            Machine Learning platform.
          </p>
          <p className="second-heading">
            It introduces
            {' '}
            <span style={{ color: "var(--operationColor)", fontWeight: "bold" }}>
            distributed development, 
            </span>
            {' '}
            a method based on best practices from Software Engineering made available to Machine Learning.
          </p>

          <p className="third-heading">
            Built on a single platform,
            it gives 
            {' '}
            <span style={{ color: "#fab81b", fontWeight: "bold" }}>
              Data Scientists 
            </span>
            {' '}
            versatility they need,
            and
            {' '}
            <span style={{ color: "#32afc3", fontWeight: "bold" }}>
              Domain Experts 
            </span>
            {' '}
            the option to directly participate in the Machine Learning 
            {' '}
            <span style={{ color: "var(--operationColor)", fontWeight: "bold" }}>
              development process.
            </span>
            {' '}
          </p>
        </div>
      </div>
    </div>
  )
}

export default DomainKnowledgeGap;
