import * as d3 from 'd3';

const xAxisLineColor = "#91BD44";
const barsColor = "#EF5967";

interface Coordinate {
  year: string;
  value: number;
}

const data: Array<Coordinate> = [
  { year: '1', value: 2 },
  { year: '2', value: 8 },
  { year: '3', value: 15 },
  { year: '4', value: 25 },
  { year: '5', value: 35 },
  { year: '6', value: 42 },
  { year: '7', value: 45 },

  { year: '8', value: 50 },
  { year: '9', value: 53 },
  { year: '10', value: 56 },
  { year: '11', value: 58 },
  { year: '12', value: 70 },
  { year: '13', value: 76 },
  { year: '14', value: 82 },

  { year: '15', value: 87 },
  { year: '16', value: 92 },
  { year: '17', value: 99 },
  { year: '18', value: 90 },
  { year: '19', value: 85 },
  { year: '20', value: 80 },
  { year: '21', value: 70 },
  { year: '22', value: 60 },
  { year: '23', value: 57 },
  { year: '24', value: 63 },

  { year: '25', value: 65 },
  { year: '26', value: 67 },
  { year: '27', value: 69 },
  { year: '28', value: 71 },
  { year: '29', value: 73 },
  { year: '30', value: 75 },
  { year: '31', value: 75 },

  { year: '32', value: 75 },
  { year: '33', value: 73 },
  { year: '34', value: 73 },
  { year: '35', value: 73 },
  { year: '36', value: 73 },
  { year: '37', value: 66 },
  { year: '38', value: 68 },
  { year: '39', value: 70 },
];

const dataForLine = [
  { x: 105, y: 450 },
  { x: 255, y: 330 },
  { x: 400, y: 290 },
  { x: 560, y: 200 },
  { x: 722.5, y: 303 },
  { x: 885, y: 265 },
  { x: 992, y: 265 }
]

export const buildGraph = () => {
  const svg = d3.select("svg"),
    margin = 200,
    width = parseInt(svg.attr("width")) + 100,
    height = parseInt(svg.attr("height")) - margin - 50;
  const xScale = d3.scaleBand().range([0, width]).padding(0.4),
    yScale = d3.scaleLinear().range([height, 0]);


  const g = svg.append("g")
    .attr("transform", "translate(" + 100 + "," + 100 + ")");

  xScale.domain(data.map(function (d) { return d.year; }));
  yScale.domain([0, d3.max(data, function (d) { return d.value; }) + 50] as Iterable<Number>);

  g.append("g")
    .attr("transform", "translate(0," + height + ")")
    .call(d3.axisBottom(xScale)
      .tickFormat(function (d) {
        return "";
      })
      .ticks(0).tickSize(0))
    .attr('stroke-width', '0.4rem')
    .attr("color", "white");

  const xAxisLine = svg.append('line')
    .attr('x1', 100)
    .attr('y1', height + 101)
    .attr('x2', 1000)
    .attr('y2', height + 101)
    .attr('stroke', xAxisLineColor)
    .attr('stroke-width', 9);

  // -------------------- Add effect to the X axis line ----------------------- //

  const xAxisLineDelay = 10500;

  const xAxisLineLength =  xAxisLine?.node()?.getTotalLength();

  const xAxisLineTransition = d3
    .transition()
    .ease(d3.easeSin)
    .duration(9000)
    .delay(xAxisLineDelay);

  xAxisLine
    .attr("stroke-dashoffset", xAxisLineLength || 0)
    .attr("stroke-dasharray", xAxisLineLength || 0)
    .transition(xAxisLineTransition)
    .attr("stroke-dashoffset", 0);

  // -------------------- ----------------------- ----------------------- //

  g.append("g")
    .call(d3.axisLeft(yScale)
      .tickFormat(function (d) {
        return "";
      })
      .ticks(0)
      .tickSize(0))
    .attr('stroke-width', '0.2rem')
    .attr("color", "white");

  svg.append("text")
    .attr("class", "y label")
    .attr("text-anchor", "end")
    .attr("y", 36)
    .attr("x", 115)
    .attr("dy", ".75em")
    .style("font-size", 22)
    .text("Start")
    .attr("fill", "white")

  svg.append("text")
    .attr("class", "y label")
    .attr("text-anchor", "end")
    .attr("y", 36)
    .attr("x", width - 100)
    .attr("dy", ".75em")
    .style("font-size", 22)
    .text("Target")
    .attr("fill", "white");

  svg.append('line')
    .attr('x1', 992)
    .attr('y1', 110)
    .attr('x2', 992)
    .attr('y2', 450)
    .attr('stroke', 'white')
    .attr('stroke-width', 3.5);

  svg.append("text")
    .attr("class", "y label")
    .attr("text-anchor", "end")
    .attr("y", 56)
    .attr("x", -206)
    .attr("dy", ".75em")
    .attr("transform", "rotate(-90)")
    .text("Deviation from optimal")
    .attr("fill", "white")

  svg.append("text")
    .attr("class", "x label")
    .attr("text-anchor", "end")
    .attr("y", 480)
    .attr("x", 156)
    .attr("dy", ".75em")
    /* .attr("transform", "rotate(-90)") */
    .text("Optimal path")
    .attr("fill", "white");

  // ------------- Draw bars and add animation --------------------//

  g.selectAll(".bar")
    .data(data)
    .enter()
    .append("rect")
    .attr("class", "bar")
    .attr("x", function (d) { return xScale(d.year); })
    .attr("y", function (d) { return yScale(0); })
    .attr("width", xScale.bandwidth())
    .attr("height", function (d) { return height - yScale(0) });

  svg.selectAll("rect")
    .transition()
    .duration(1400)
    .delay(8000)
    .attr("y", function (d) {
      const coord: Coordinate = d as Coordinate;
      return yScale(coord?.value);
    })
    .attr("height", function (d) {
      const coord: Coordinate = d as Coordinate;
      return height - yScale(coord?.value);
    });

  // --------------------------------------------------------//

  // Sign to be placed on top of bars
  const higherPotentialCostsY = 290;
  svg.append('rect')
    .attr('x', 490)
    .attr('y', higherPotentialCostsY)
    .transition()
    .delay(10500)
    .duration(100)
    .ease(d3.easeSinIn)
    .attr('width', 150)
    .attr('height', 50)
    .attr('stroke-width', '0.2rem')
    .attr('stroke', '#1c2b40')
    .attr('fill', '#1c2b40');

  svg.append('text')
    .attr('x', 497)
    .attr('y', higherPotentialCostsY + 20)
    .transition()
    .delay(10500)
    .duration(100)
    .ease(d3.easeSinIn)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("Higher potential")


  svg.append('text')
    .attr('x', 540)
    .attr('y', higherPotentialCostsY + 40)
    .transition()
    .delay(10500)
    .duration(100)
    .ease(d3.easeSinIn)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("costs");

  const risksSignY = 340;
  const risksSignX = 800;

  svg.append('rect')
    .attr('x', risksSignX - 10)
    .attr('y', risksSignY)
    .transition()
    .delay(10500)
    .duration(100)
    .ease(d3.easeSinIn)
    .attr('width', 150)
    .attr('height', 50)
    .attr('stroke-width', '0.2rem')
    .attr('stroke', '#1c2b40')
    .attr('fill', '#1c2b40');

  svg.append('text')
    .attr('x', risksSignX)
    .attr('y', risksSignY + 20)
    .transition()
    .delay(10500)
    .duration(100)
    .ease(d3.easeSinIn)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("Risks of useless");

  svg.append('text')
    .attr('x', risksSignX)
    .attr('y', risksSignY + 40)
    .transition()
    .delay(10500)
    .duration(100)
    .ease(d3.easeSinIn)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("model output");


  // ---------------------- Draw the first text sign --------------------------//

  const firstTextDelay = 2550;

  svg.append('rect')
    .attr('x', 200)
    .attr('y', 100)
    .transition()
    .duration(100)
    .ease(d3.easeSinIn)
    .delay(firstTextDelay)
    .attr('width', 105)
    .attr('height', 50)
    .attr('stroke-width', '0.2rem')
    .attr('stroke', '#fab81b')
    .attr('fill', 'transparent')

  svg.append('line')
    .attr('x1', 255)
    .attr('y1', 150)
    .attr('x2', 255)
    .attr('y2', 322)
    .transition()
    .duration(100)
    .ease(d3.easeSinIn)
    .delay(firstTextDelay)
    .attr('stroke', 'white')
    .attr('stroke-width', 2.5);

  svg.append('text')
    .attr('x', 215)
    .attr('y', 120)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(firstTextDelay)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("Problem")

  svg.append('text')
    .attr('x', 215)
    .attr('y', 140)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(firstTextDelay)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("definition");

  // ----------------------------------------------------------------------- //

  // ------------------- Draw the second text ----------------------------- //

  const secondTextDelay = 3250;

  svg.append('rect')
    .attr('x', 340)
    .attr('y', 100)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(secondTextDelay + 200)
    .attr('width', 105)
    .attr('height', 50)
    .attr('stroke-width', '0.2rem')
    .attr('stroke', '#fab81b')
    .attr('fill', 'transparent');
  
  svg.append('line')
    .attr('x1', 400)
    .attr('y1', 150)
    .attr('x2', 400)
    .attr('y2', 290)
    .transition()
    .duration(100)
    .ease(d3.easeSinIn)
    .delay(secondTextDelay + 200)
    .attr('stroke', 'white')
    .attr('stroke-width', 2.5);

  svg.append('text')
    .attr('x', 350)
    .attr('y', 120)
    .transition()
    .duration(600)
    .ease(d3.easeSin)
    .delay(secondTextDelay + 200)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("Data");

  svg.append('text')
    .attr('x', 350)
    .attr('y', 140)
    .transition()
    .duration(600)
    .ease(d3.easeSin)
    .delay(secondTextDelay + 200)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("acquisition");

  const dataPreparationTextX = 515;
  const dataPreparationTextY = 120;

  // ------------------------------------------------------- //

  // ---------------------- Draw third text content --------- //
  
  const thirdTextDelay = 4500;

  svg.append('rect')
    .attr('x', dataPreparationTextX - 10)
    .attr('y', dataPreparationTextY - 20)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(thirdTextDelay + 200)
    .attr('width', 115)
    .attr('height', 50)
    .attr('stroke-width', '0.2rem')
    .attr('stroke', '#fab81b')
    .attr('fill', 'transparent');

  svg.append('line')
    .attr('x1', dataPreparationTextX + 45)
    .attr('y1', dataPreparationTextY + 30)
    .attr('x2', dataPreparationTextX + 45)
    .attr('y2', dataPreparationTextY + 75)
    .transition()
    .duration(100)
    .ease(d3.easeSinIn)
    .delay(thirdTextDelay + 200)
    .attr('stroke', 'white')
    .attr('stroke-width', 2.5);

  svg.append('text')
    .attr('x', dataPreparationTextX)
    .attr('y', dataPreparationTextY)
    .transition()
    .duration(600)
    .ease(d3.easeSin)
    .delay(thirdTextDelay + 200)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("Data");

  svg.append('text')
    .attr('x', dataPreparationTextX)
    .attr('y', dataPreparationTextY + 20)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(thirdTextDelay + 200)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("preparation");

  // --------------------------------------------------------------------- //

  const modelingTextX = 680;
  const modelingTextY = 120;

  const forthTextDelay = 5500;

  svg.append('rect')
    .attr('x', modelingTextX - 10)
    .attr('y', modelingTextY - 20)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(forthTextDelay + 200)
    .attr('width', 105)
    .attr('height', 50)
    .attr('stroke-width', '0.2rem')
    .attr('stroke', '#fab81b')
    .attr('fill', 'transparent');

  svg.append('line')
    .attr('x1', modelingTextX + 42.5)
    .attr('y1', modelingTextY + 30)
    .attr('x2', modelingTextX + 42.5)
    .attr('y2', modelingTextY + 175)
    .transition()
    .duration(100)
    .ease(d3.easeSinIn)
    .delay(forthTextDelay + 200)
    .attr('stroke', 'white')
    .attr('stroke-width', 2.5);

  svg.append('text')
    .attr('x', modelingTextX)
    .attr('y', modelingTextY)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(forthTextDelay + 200)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("Modelling");

  // --------------------------- Last top text -------------------------- //

  const modelValidationTextX = 840;
  const modelValidationTextY = 120

  const fifthTextDelay = 6800;

  svg.append('rect')
    .attr('x', modelValidationTextX - 10)
    .attr('y', modelValidationTextY - 20)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(fifthTextDelay + 200)
    .attr('width', 105)
    .attr('height', 50)
    .attr('stroke-width', '0.2rem')
    .attr('stroke', '#fab81b')
    .attr('fill', 'transparent');
  
  svg.append('line')
    .attr('x1', modelValidationTextX + 45)
    .attr('y1', modelValidationTextY + 30)
    .attr('x2', modelValidationTextX + 45)
    .attr('y2', modelValidationTextY + 150)
    .transition()
    .duration(100)
    .ease(d3.easeSinIn)
    .delay(fifthTextDelay + 200)
    .attr('stroke', 'white')
    .attr('stroke-width', 2.5);

  svg.append('text')
    .attr('x', modelValidationTextX)
    .attr('y', modelValidationTextY)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(fifthTextDelay + 200)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("Model");

  svg.append('text')
    .attr('x', modelValidationTextX)
    .attr('y', modelValidationTextY + 20)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(fifthTextDelay + 200)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("Validation");

  // ---------------------------------------------------------------------------- //

  const firstXAxisTextDelay = xAxisLineDelay + 2000;
  const increasedAccuracyX = 200;
  const bottomTextY = height + 175;

  svg.append('line')
    .attr('x1', increasedAccuracyX + 55)
    .attr('y1', bottomTextY - 70)
    .attr('x2', increasedAccuracyX + 55)
    .attr('y2', bottomTextY - 15)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(firstXAxisTextDelay + 200)
    .attr('stroke', xAxisLineColor)
    .attr('stroke-width', 2.5);

  svg.append('text')
    .attr('x', increasedAccuracyX)
    .attr('y', bottomTextY)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(firstXAxisTextDelay + 200)
    .attr("width", 20)
    .attr("fill", xAxisLineColor)
    .style("font-size", 19)
    .style("font-weight", "bold")
    .text("Increased");

  svg.append('text')
    .attr('x', increasedAccuracyX)
    .attr('y', bottomTextY + 20)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(firstXAxisTextDelay + 200)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("accuracy &");

  svg.append('text')
    .attr('x', increasedAccuracyX)
    .attr('y', bottomTextY + 40)
    .transition()
    .duration(100)
    .ease(d3.easeSin)
    .delay(firstXAxisTextDelay + 200)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("precision");

  const secondXAxisTextDelay = firstXAxisTextDelay + 1300;
  const text2x = increasedAccuracyX + 110;

  svg.append('line')
    .attr('x1', text2x + 90)
    .attr('y1', bottomTextY - 70)
    .attr('x2', text2x + 90)
    .attr('y2', bottomTextY - 15)
    .transition()
    .delay(secondXAxisTextDelay)
    .ease(d3.easeSin)
    .attr('stroke', xAxisLineColor)
    .attr('stroke-width', 2.5);

  svg.append('text')
    .attr('x', text2x)
    .attr('y', bottomTextY)
    .transition()
    .delay(secondXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", xAxisLineColor)
    .style("font-size", 19)
    .style("font-weight", "bold")
    .text("Removed burden ");

  svg.append('text')
    .attr('x', text2x)
    .attr('y', bottomTextY + 20)
    .transition()
    .delay(secondXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("and higher data");

  svg.append('text')
    .attr('x', text2x)
    .attr('y', bottomTextY + 40)
    .transition()
    .delay(secondXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("data quality");

  const text3x = increasedAccuracyX + 270;
  const thirdXAxisTextDelay = secondXAxisTextDelay + 1300;

  svg.append('line')
    .attr('x1', text3x + 90)
    .attr('y1', bottomTextY - 70)
    .attr('x2', text3x + 90)
    .attr('y2', bottomTextY - 15)
    .transition()
    .delay(thirdXAxisTextDelay)
    .ease(d3.easeSin)
    .attr('stroke', xAxisLineColor)
    .attr('stroke-width', 2.5);

  svg.append('text')
    .attr('x', text3x)
    .attr('y', bottomTextY)
    .transition()
    .delay(thirdXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", xAxisLineColor)
    .style("font-size", 19)
    .style("font-weight", "bold")
    .text("Provided guidance");

  svg.append('text')
    .attr('x', text3x)
    .attr('y', bottomTextY + 20)
    .transition()
    .delay(thirdXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("on features");

  svg.append('text')
    .attr('x', text3x)
    .attr('y', bottomTextY + 40)
    .transition()
    .delay(thirdXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", xAxisLineColor)
    .style("font-size", 19)
    .text("& support in");

  svg.append('text')
    .attr('x', text3x)
    .attr('y', bottomTextY + 60)
    .transition()
    .delay(thirdXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("preprocessing task");

  const forthXAxisTextDelay = thirdXAxisTextDelay + 1100;
  const text4x = increasedAccuracyX + 450;

  svg.append('line')
    .attr('x1', text4x + 75)
    .attr('y1', bottomTextY - 70)
    .attr('x2', text4x + 75)
    .attr('y2', bottomTextY - 15)
    .transition()
    .delay(forthXAxisTextDelay)
    .ease(d3.easeSin)
    .attr('stroke', xAxisLineColor)
    .attr('stroke-width', 2.5);

  svg.append('text')
    .attr('x', text4x)
    .attr('y', bottomTextY)
    .transition()
    .delay(forthXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", xAxisLineColor)
    .style("font-size", 19)
    .style("font-weight", "bold")
    .text("Improved model ");

  svg.append('text')
    .attr('x', text4x)
    .attr('y', bottomTextY + 20)
    .transition()
    .delay(forthXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .style("font-weight", "bold")
    .text("output architecture");

  const text5x = increasedAccuracyX + 635;
  const fifthXAxisTextDelay = forthXAxisTextDelay + 1100;

  svg.append('line')
    .attr('x1', text5x + 50)
    .attr('y1', bottomTextY - 70)
    .attr('x2', text5x + 50)
    .attr('y2', bottomTextY - 15)
    .transition()
    .delay(fifthXAxisTextDelay)
    .ease(d3.easeSin)
    .attr('stroke', xAxisLineColor)
    .attr('stroke-width', 2.5);

  svg.append('text')
    .attr('x', text5x)
    .attr('y', bottomTextY)
    .transition()
    .delay(fifthXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", xAxisLineColor)
    .style("font-size", 19)
    .style("font-weight", "bold")
    .text("Gained insights on");

  svg.append('text')
    .attr('x', text5x)
    .attr('y', bottomTextY + 20)
    .transition()
    .delay(fifthXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 40)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("model performance");

  svg.append('text')
    .attr('x', text5x)
    .attr('y', bottomTextY + 40)
    .transition()
    .delay(fifthXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text(" and result");

  svg.append('text')
    .attr('x', text5x)
    .attr('y', bottomTextY + 60)
    .transition()
    .delay(fifthXAxisTextDelay)
    .ease(d3.easeSin)
    .attr("width", 20)
    .attr("fill", "white")
    .style("font-size", 19)
    .text("communication");

  // ------------------------- //

  // Draw top line
  var lineFunc = d3.line()
    .x(function (d) { return d.x })
    .y(function (d) { return d.y });

  // Add the path using this helper function
  const updatedPath = svg.append('path')
    .attr('d', lineFunc(dataForLine))
    .attr('stroke', barsColor)
    .attr('fill', 'none')
    .attr('stroke-width', '0.2rem');

  // Add animation to the line

  const pathLength = updatedPath?.node()?.getTotalLength();

  const transitionPath = d3
    .transition()
    .ease(d3.easeSin)
    .duration(9000);

  updatedPath
    .attr("stroke-dashoffset", pathLength || 0)
    .attr("stroke-dasharray", pathLength || 0)
    .transition(transitionPath)
    .attr("stroke-dashoffset", 0);

  svg.selectAll("circle")
    .append("g")
    .data(dataForLine)
    .enter()
    .append("circle")
    .transition()
    .duration(100)
    .ease(d3.easeSinIn)
    .delay((params) => {
      const indexOfElement = dataForLine.indexOf(params);
      let delay = 0;

      if(indexOfElement === 0) {
        delay = 0;
      } else if(indexOfElement === 1) {
        delay = firstTextDelay;
      } else if (indexOfElement === 2) {
        delay = secondTextDelay
      } else if (indexOfElement === 3) {
        delay = thirdTextDelay
      } else if (indexOfElement === 4) {
        delay = forthTextDelay
      } else if (indexOfElement === 5) {
        delay = fifthTextDelay;
      } else {
        delay = fifthTextDelay + 1500;
      }
      
      return delay;
    })
    .attr("r", 8)
    .attr("cx", d => d.x)
    .attr("cy", d => d.y)
    .style("fill", d => barsColor)
   ;


  // -------- Add X axis circles to the graph. They must be last because interfere with the line chart points ---- //

  const xAxisCirclesRadio = 9 ;

  svg.append('circle')
    .attr('cx', increasedAccuracyX + 55)
    .attr('cy', 450)
    .transition()
    .delay(firstXAxisTextDelay)
    .ease(d3.easeSin)
    .attr('r', xAxisCirclesRadio)
    .attr('stroke', xAxisLineColor)
    .attr('fill', xAxisLineColor);

  svg.append('circle')
    .attr('cx', text2x + 90)
    .attr('cy', 450)
    .transition()
    .delay(secondXAxisTextDelay)
    .ease(d3.easeSin)
    .attr('r', xAxisCirclesRadio)
    .attr('stroke', xAxisLineColor)
    .attr('fill', xAxisLineColor);

  svg.append('circle')
    .attr('cx', text3x + 90)
    .attr('cy', 450)
    .transition()
    .delay(thirdXAxisTextDelay)
    .ease(d3.easeSin)
    .attr('r', xAxisCirclesRadio)
    .attr('stroke', xAxisLineColor)
    .attr('fill', xAxisLineColor);

  svg.append('circle')
    .attr('cx', text4x + 75)
    .attr('cy', 450)
    .transition()
    .delay(forthXAxisTextDelay)
    .ease(d3.easeSin)
    .attr('r', xAxisCirclesRadio)
    .attr('stroke', xAxisLineColor)
    .attr('fill', xAxisLineColor);

  svg.append('circle')
    .attr('cx', text5x + 50)
    .attr('cy', 450)
    .transition()
    .delay(fifthXAxisTextDelay)
    .ease(d3.easeSin)
    .attr('r', xAxisCirclesRadio)
    .attr('stroke', xAxisLineColor)
    .attr('fill', xAxisLineColor);

  svg.append('circle')
    .attr('cx', text5x + 156)
    .attr('cy', 450)
    .attr('r', xAxisCirclesRadio)
    .attr('stroke', xAxisLineColor)
    .attr('fill', xAxisLineColor);

  setTimeout(() => {
    svg.selectAll("*").remove();
  }, 25000);
}
