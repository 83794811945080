import * as React from 'react';
import Card from '../../UI/Card/Card';
import cardsData from './cardsData';
import './SuccessStories.scss';

const SuccessStories = (props) => {
  const { displayed } = props;
  return (
    <div className={`success-stories displayed`}>
      <p className="title">
        Built for success
      </p>
      <div className="success-stories-content">
        {cardsData.map((cd) => (
          <Card
            className="success-story-card"
            icon={cd.icon}
            title={cd.title}
            description={cd.htmlDescription} 
            imgUrl={cd.imgUrl} 
          />
        ))}
      </div>
    </div>
  )
}

export default SuccessStories;
