import React, { ReactElement } from "react";
import satellites from '../../images/satellites.png';
import warCar from '../../images/warCar.jpg';
import books from '../../images/books.jpg';

interface CardInfo {
  title: string;
  htmlDescription: ReactElement;
  imgUrl: string;
  icon: string;
}

const cardsData: Array<CardInfo> = [{
  title: "European Space Agency",
  htmlDescription: (
    <div>
      <p style={{ fontSize: "1.1rem", fontStyle: "italic" }}>
        Landmark classification & segmentation using Earth Observation (EO) satellite imagery
      </p>
      <div>
        <p style={{ fontSize: "1.1rem", color: "var(--operationColor)", fontWeight: "bold" }}>
          Development
        </p>
        <p  style={{ fontSize: "1rem" }}>
          Multidisciplinary team of data scientists, 
          EO experts and researchers working on data, 
          code and pipelines using state-of-the-art 
          Machine Learning techniques.
        </p>
        <p style={{ fontSize: "1.1rem", color: "var(--dataProjColor)", fontWeight: "bold" }}>
          Outcome
        </p>
        <ul>
          <li>
            <p style={{ fontSize: "1rem" }}>
              94% accuracy in classification and segmentation task
            </p>
          </li>
          <li>
            <p style={{ fontSize: "1rem" }}>
              100% reproducible for all participants
            </p>
          </li>
          <li>
            <p style={{ fontSize: "1rem" }}>
              3-week development time instead of several months
            </p>
          </li>
        </ul>
      </div>
    </div>
  ),
  imgUrl: satellites,
  icon: ""
}, {
  title: "SAAB AG",
  imgUrl: warCar,
  icon: "",
  htmlDescription: (
    <div>
      <p style={{ fontSize: "1.1rem", fontStyle: "italic" }}>
        Product innovation for autonomous radar systems
      </p>
      <div>
        <p style={{ fontSize: "1.1rem", color: "var(--operationColor)", fontWeight: "bold" }}>
          Development
        </p>
        <p  style={{ fontSize: "1rem" }}>
          Joint development of SAAB´s Center of Excellence with internal product innovation, 
          radar data specialists and engineers on one platform. 
        </p>
        <p style={{ fontSize: "1.1rem", color: "var(--dataProjColor)", fontWeight: "bold" }}>
          Outcome
        </p>
        <ul>
          <li>
            <p style={{ fontSize: "1rem" }}>
              Much faster innovation cycles within a highly regulated market.
            </p>
          </li>
          <li>
            <p style={{ fontSize: "1rem" }}>
              Build-up of reusable knowledge base for future innovations (i.e. datasets & models)
            </p>
          </li>
          <li>
            <p style={{ fontSize: "1rem" }}>
              Running on offline & on-prem servers
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
},
{
  title: "Schwabe, Ley & Greiner (SLG)",
  imgUrl: books,
  icon: "",
  htmlDescription: (
    <div>
      <p style={{ fontSize: "1.1rem", fontStyle: "italic" }}>
        Semantic detection of critical passages in treasury contracts
      </p>
      <div>
        <p style={{ fontSize: "1.1rem", color: "var(--operationColor)", fontWeight: "bold" }}>
          Development
        </p>
        <p  style={{ fontSize: "1rem" }}>
          Lawyers and treasury consultants from SLG collaborate in the development with MLReef Agency to develop 
          the Natural Language Processing model.
        </p>
        <p style={{ fontSize: "1.1rem", color: "var(--dataProjColor)", fontWeight: "bold" }}>
          Outcome
        </p>
        <ul>
          <li>
            <p style={{ fontSize: "1rem" }}>
              92% accuracy in detecting multiple clauses within highly unstructured documents.
            </p>
          </li>
          <li>
            <p style={{ fontSize: "1rem" }}>
              Enables SLG to screen thousands of legal documents within minutes.
            </p>
          </li>
        </ul>
      </div>
    </div>
  )
}];

export default cardsData;