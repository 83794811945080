
export const buildCircles = (ctx: CanvasRenderingContext2D): void => {
  const leftCircleCenterXCoord = 175;
  const leftCircleCenterYCoord = 170;
  const rightCircleCenterXCoord = 535;
  const rightCircleCenterYCoord = 170;
  const circlesRadious = 150;

  ctx.beginPath();
  ctx.arc(leftCircleCenterXCoord, leftCircleCenterYCoord, circlesRadious, 0, 2 * Math.PI);
  ctx.strokeStyle = "#32afc3";
  ctx.lineWidth = 7.5;
  ctx?.stroke();
  ctx.font = "1.3rem Roboto bold";
  ctx.fillStyle = "#32afc3";
  ctx.textAlign = "center";
  ctx.fillText("Domain Expert", leftCircleCenterXCoord, leftCircleCenterYCoord);

  ctx.beginPath();
  ctx.arc(rightCircleCenterXCoord, rightCircleCenterYCoord, circlesRadious, 0, 2 * Math.PI);
  ctx.strokeStyle = "#fab81b";
  ctx.lineWidth = 7.5;
  ctx?.stroke();
  ctx.font = "1.3rem Roboto bold";
  ctx.fillStyle = "#fab81b";
  ctx.textAlign = "center";
  ctx.fillText("Data Scientist", rightCircleCenterXCoord, rightCircleCenterYCoord); 
}