import * as React from 'react';
import { drawArrow } from './functions';
import './SolvedProblems.scss';

const SolvedProblems = (props) => {
  const { displayed } = props;
  const canvas1 = React.useRef<HTMLCanvasElement>();
  const canvas2 = React.useRef<HTMLCanvasElement>();
  React.useEffect(() => {
    const context = canvas1.current?.getContext("2d");
    const context2 = canvas2.current?.getContext("2d");
    if (context) {
      const finalMiddlePoint = (canvas1.current?.width || 100) / 2;
      drawArrow(context, finalMiddlePoint, 0, finalMiddlePoint, 80, 10, '#b2b2b2')
    }
    if (context2) {
      const finalMiddlePoint = (canvas2.current?.width || 100) / 2;
      drawArrow(context2, finalMiddlePoint, 0, finalMiddlePoint, 80, 10, '#b2b2b2')
    }
  }, []);
  
  return (
    <div className={`solved-problems displayed`}>
      <div className="solved-problems-content">
        <p>
          To combine both, two things are needed:
        </p>
        <div className="solved-problems-content-cards">
          <div className="solved-problems-content-cards-left">
            <p className="first-text">Operationalize</p>
            <p className="first-text" style={{ color: "#fab81b" }}>
              ML development
            </p>
            <p className="second-text">
              What problems does it solve?
            </p>
            <canvas height={100} ref={canvas1} />
            <p className="third-text">
              Efficiency,
            </p>
            <p className="third-text">
              Reproducibility & 
            </p>
            <p className="third-text">
              Governance
            </p>
          </div>
          <div className="solved-problems-content-cards-right">
            <p className="first-text">
              Increase
            </p>
            <p className="first-text" style={{ color: "var(--info)" }}>accessibility to ML</p>
            <p className="second-text">
              What problems does it solve?
            </p>
            <canvas height={100} ref={canvas2} />
            <p  className="third-text">
              Data Science 
              Bottleneck
            </p>
            <p className="third-text">
              &
            </p>
            <p className="third-text">
            Domain 
            Knowledge 
            Gap
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SolvedProblems;