import * as React from 'react';
import './TimeToValue.scss';
import dataset from '../../images/dataset.svg';
import DataOperators from '../../images/DataOperators.svg';
import Models from '../../images/Models.svg';

const TimeToValue = (props) => {
  const { displayed } = props;
  return (
    <div className={`time-to-value displayed`}>
      <p className="title">
        Get <span style={{ color: "var(--dataProjColor)" }}> best time-to-value </span> with ready to use
      </p>
      <p className="title">
        & open-source content
      </p>
      <div className="time-to-value-content">
        <div className="time-to-value-content-card">
          <img src={dataset} alt="" width={50} />
          <p className="time-to-value-content-card-title">Medical Datasets</p>
          <p className="time-to-value-content-card-desc">
            Leverage your 
            <span style={{ color: "#fab81b" }}>
               Data Science
            </span> 
             capacity by giving anyone easy access
            to hundreds of GB of medical datasets. 
          </p>
        </div>
        <div className="time-to-value-content-card">
          <img src={DataOperators} alt="" width={50} color={"white"} />
          <p className="time-to-value-content-card-title">
            Data Operations
          </p>
          <p className="time-to-value-content-card-desc">
            Allow anyone to participate in data cleaning, 
            data preprocessing and visualizing data. 
          </p>
        </div>
        <div className="time-to-value-content-card">
          <img src={Models} alt="" width={50} />
          <p className="time-to-value-content-card-title">
            Machine Learning Models
          </p>
          <p className="time-to-value-content-card-desc">
            Get the first results quickly and access state-of-the-art
            models on one platform. 
          </p>
        </div>
      </div>
    </div>
  )
}

export default TimeToValue;
