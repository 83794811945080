import * as React from "react";
import "./ImagesCollection.scss";

interface CollectionItem {
  mainImageUrl: string;
  additionalImages: Array<string>;
  description: string;
}


interface ImagesCollectionProps {
  collection: Array<CollectionItem>;
  timeInterval: number;
}

const ImagesCollection: React.FC<ImagesCollectionProps> = (props: ImagesCollectionProps) => {
  const [currentImageInd, setCurrentImageInd] = React.useState(0);
  const indicators = [...Array(props.collection.length).keys()];


  React.useEffect(() => {
    const interval = setInterval(
      () => setCurrentImageInd(
        currentImageInd === props.collection.length - 1
          ? 0
          : currentImageInd + 1
      ),
      props.timeInterval
    );
    return () => {
      clearInterval(interval);
    }
  }, [currentImageInd]);

  return (
    <div className="images-collection">
      <p className="description">
        {props.collection[currentImageInd].description}
      </p>
      <div className="images-collection-content">
        <div className="images-collection-content-current-img">
          <div className="left">
            <img src={props.collection[currentImageInd].additionalImages[0]} alt="" width={150} />
            <img src={props.collection[currentImageInd].additionalImages[1]} alt="" width={150} />
          </div>
          <img src={props.collection[currentImageInd].mainImageUrl} alt="" width={400} />
          <div className="right">
            <img src={props.collection[currentImageInd].additionalImages[2]} alt="" width={150} />
            <img src={props.collection[currentImageInd].additionalImages[3]} alt="" width={150} />
          </div>
        </div>
        <div className="images-collection-content-band">
          {indicators.map(
            (ind) => (
              <div
                key={ind}
                className={`images-collection-content-band-indicator ${ind === currentImageInd
                  ? "active"
                  : "inactive"} `
                }
              />
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default ImagesCollection;
