import * as React from "react";
import SlideCollection from "../components/SlideCollection/SlideCollection";
import CirclesSection from "../components/CirclesSection/CirclesSection";
import GraphSection from "../components/GraphSection/GraphSection";
import mlreefIc from '../images/MLReef_Logo.png';
import './index.scss';
import DomainKnowledgeGap from "../components/DomainKnowledgeGap/DomainKnowledgeGap";
import SuccessStories from "../components/SuccessStories/SuccessStories";
import KnowMoreAbout from "../components/KnowMoreAbout/KnowMoreAbout";
import SolvedProblems from "../components/SolvedProblems/SolvedProblems";
import DSBottleneckProblem from "../components/DSBottleneckProblem/DSBottleneckProblem";
import LeverageTheWork from "../components/LeverageTheWork/LeverageTheWork";
import TimeToValue from "../components/TimeToValue/TimeToValue";
import PointsEffect from "../components/PointsEffect/PointsEffect";

const IndexPage = () => {
  const mainDiv = React.useRef<HTMLDivElement>(null);
  const section1 = React.useRef<HTMLElement>(null);
  const section2 = React.useRef<HTMLElement>(null);
  const section3 = React.useRef<HTMLElement>(null);
  const section4 = React.useRef<HTMLElement>(null);
  const section5 = React.useRef<HTMLElement>(null);
  const section6 = React.useRef<HTMLElement>(null);
  const section7 = React.useRef<HTMLElement>(null);
  const section8 = React.useRef<HTMLElement>(null);
  const section9 = React.useRef<HTMLElement>(null);
  const section10 = React.useRef<HTMLElement>(null);

  const sections = [
    section1,
    section2,
    section3,
    section4,
    section5,
    section6,
    section7,
    section8,
    section9,
    section10
  ];

  const handleOnScrollDown = () => {
    const pageTop = mainDiv.current?.scrollTop || 0;
    const pageBottom = pageTop + window.innerHeight;

    sections.forEach((section) => {
      const top = section.current?.getBoundingClientRect().top || 0;

      if (top < pageBottom) {
        section.current?.classList.add("visible");
      }
    });
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleOnScrollDown);

    return () => window.removeEventListener('scroll', handleOnScrollDown);
  }, [handleOnScrollDown]);
  
  return (
    <main className="index">
      <div ref={mainDiv} className="index-content">
        <PointsEffect>
          <div className="index-content-head">
            <div className="index-content-head-presentation">
              <img className="mlreef-icon" src={mlreefIc} alt="" />
              <p className="text-1">
              The Secure 
                {' '}
                <span style={{ color: "var(--operationColor)", fontWeight: "bold" }}>
                  multipersona
                </span>
                {' '}
                ML Collaboration Platform for Regulated Industries
              </p>
              <p className="text-2">
                MLReef is a Machine Learning development platform
              </p>
              <p className="text-3">
                that solves the
                {' '}
                <span style={{ color: "var(--dataProjColor)" }}>
                  Data Science Bottleneck
                </span>
                {' '}
                &
                {' '}
                <span style={{ color: "var(--dataProjColor)" }}>
                  Domain Knowledge Gap
                </span>
              </p>
            </div>

          </div>
        </PointsEffect>


        <div className="index-content-sections">
          <p className="text-1">
            What are
            {' '}
            <span style={{ color: 'var(--operationColor)' }}>
              multipersona ML
            </span>
            {' '}
            use cases?
          </p>
          <section ref={section1}>
            <SlideCollection />
          </section>
          <section ref={section2}>
            <CirclesSection />
          </section>
          <section ref={section3}>
            <SolvedProblems />
          </section>
          <section ref={section4}>
            <DSBottleneckProblem />
          </section>
          <section ref={section5}>
            <GraphSection />
          </section>
          <section ref={section6}>
            <DomainKnowledgeGap />
          </section>
          <section ref={section7}>
            <LeverageTheWork />
          </section>
          <section ref={section8}>
            <TimeToValue />
          </section>
          <section ref={section9}>
            <SuccessStories />
          </section>
          <section ref={section10}>
            <KnowMoreAbout />
          </section>
        </div>

      </div>
    </main>
  )
}

export default IndexPage
