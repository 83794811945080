import * as React from 'react';
import './SlideCollection.scss';
import eye1 from "./eye1.jpeg";
import eye2 from "./eye2.jpeg";
import eye3 from "./eye3.jpeg";
import eye4 from "./eye4.jpeg";
import eye5 from "./eye5.jpeg";
import lungCancerMain from "./lungCancerMain.png";
import lungCancer from "./lungCancer.png";
import lungCancer1 from "./lungCancer (1).png";
import lungCancer2 from "./lungCancer (2).png";
import lungCancer3 from "./lungCancer (3).png";
import ImagesCollection from '../../UI/ImagesCollection/ImagesCollection';

const timeToChangeImage = 3000;

const SlideCollection = (props) => {
  const { displayed } = props;
  return (
    <div className={`slide-collection displayed`}>
      <ImagesCollection 
        collection={[
          { 
            mainImageUrl: eye1, 
            description: "Identify signs of diabetic retinopathy in eye images", 
            additionalImages: [eye2, eye3, eye4, eye5]
          },
          { 
            mainImageUrl: lungCancerMain, 
            description: "Pulmonary cancer detection & segmentation", 
            additionalImages: [lungCancer, lungCancer1, lungCancer2, lungCancer3] 
          }
        ]}
        timeInterval={timeToChangeImage}
      />
    </div>
  )
}

export default SlideCollection;
