import * as React from 'react';
import './LeverageTheWork.scss';
import leveragePIC from  './leveragePIC.jpg';

const LeverageTheWork = (props) => {
  const { displayed } = props;
  return (
    <div className={`leverage-the-work displayed`}>
      <div className="leverage-the-work-title-div">
        <p>
          Leverage the work of {' '}
          <span style={{ color: "var(--modelColor)" }}>
            data scientists
          </span>
          {' '}
          towards
          {' '}
          <span style={{ color: "var(--info)" }}>
            non-technicians
          </span>
          via a hybrid of
          {' '}
          <span style={{ color: "var(--dataProjColor)" }}>
            pro-code & no-code development approach
          </span>
        </p>
      </div>
      <div className="leverage-the-work-content">
        <img src={leveragePIC} alt="" width={700} />
      </div>
    </div>
  )
}

export default LeverageTheWork;
